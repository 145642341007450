import styled from 'styled-components';

const CustomUnderline = styled.div`
  position: relative;
  display: inline-block;

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer; 
    &::after {
      top: 5%;
      height: 20px;
      transition: 0.125s ease all;
      width: 100%;
      left: 0%;
      background-color: rgba(245,200,0,0.75);
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 90%;
    height: 1px;
    width: 100%;
    left: 0%;
    background-color: rgba(0, 0, 0, 1);
    transition: 0.5s ease all;
    z-index: -1;
  }
`;

export default CustomUnderline;
