import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CustomUnderline from './customUnderline';

const ExternalLinkUnderline = ({ href, children, style }) => (
  <CustomUnderline>
    <a style={{ ...style }} href={href} target="_blank" rel="noopener noreferrer">{children}</a>
  </CustomUnderline>
);

const ExternalLink = ({ href, children, style }) => (
  <a style={{ ...style }} href={href} target="_blank" rel="noopener noreferrer">{children}</a>
);

export { ExternalLink, ExternalLinkUnderline };
